import './index.css';
import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserApp from "./Users";
import StorageApp from "./Storages";
import ItemApp from "./Items";
import LogApp from "./Logs";
import Login from "./Login";
import Logout from "./Logout";
import Status from "./Status";
import MyPage from "./MyPage";
import FetchScan from "./FetchScan";
import FetchQRList from "./FetchQRList";
import Header from "./Header";
import AuthProvider from "./AuthProvider";
import * as serviceWorker from './serviceWorker';

createRoot(document.getElementById('root')).render(
  <AuthProvider>
  <Router>
    <Header />
    <UserApp />
    <StorageApp />
    <ItemApp />
    <LogApp />
    <Routes>
      <Route path="/"         element={<MyPage />} />
      <Route path="login/"    element={<Login />} />
      <Route path="logout/"   element={<Logout />} />
      <Route path="qr/"       element={<FetchQRList />} />
      <Route path="scan/:id"  element={<FetchScan />} />
      <Route path="status/"   element={<Status />} />
    </Routes>
  </Router>
  </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
