import React, { useState, useEffect } from "react";
import QRCode from "react-qr-code";
import Fetch from "./Fetch";
import { URL, TYPE, ACTION } from "./URL";
import { get_qr_code_token } from "./util";

export default function FetchQRList() {
    return (
        <Fetch
            url={new URL(ACTION.LIST, TYPE.ITEM, 0, {"order": "storage"})}
            renderSuccess={(data) => <RenderSuccess data={data.data} />}
        />
    );
}

export function RenderSuccess({data}) {
    const [isQRShown, setQRShown]           = useState(false);
    const [checkedItems, setCheckedItems]   = useState({});

    useEffect(() => {
        const initialCheckedItems = {};
        data.forEach(item => {
            if (item.storage == null) {
                initialCheckedItems[item.id] = true;
            }
        });
        setCheckedItems(initialCheckedItems);
    }, [data]);

    if (!data) {
        return <div>データがありません</div>;
    }

    if (isQRShown)  return <QRList data={data} checkedItems={checkedItems} />;
    else            return <CheckBoxList data={data} checkedItems={checkedItems} setCheckedItems={setCheckedItems} setQRShown={setQRShown} />;
}

export function CheckBoxList({data, checkedItems, setCheckedItems, setQRShown}) {

    const handleCheckboxChange = (itemId) => {
        setCheckedItems(prevState => ({
            ...prevState,
            [itemId]: !prevState[itemId]
        }));
    };

    const handlePrintPage = () => {
        setQRShown(true);
        window.scrollTo(0, 0);
    };

    return (
        <div>
            {data.map((item) => (
                <p key={item.id}>
                    <input
                        type="checkbox"
                        checked={checkedItems[item.id] || false}
                        onChange={() => handleCheckboxChange(item.id)}
                    />
                    {item.id}: {item.name}
                    {item.storage ? (" " + item.storage.name) : (" 倉庫なし")}
                </p>
            ))}
            <button onClick={handlePrintPage}>印刷ページを表示</button>
        </div>
    );
}

export function QRList({data, checkedItems}) {

    const truncateText = (text, maxLength) => {
        // const postfix = '...';
        const postfix = '';
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + postfix;
        }
        return text;
    }
    
    const splitDataIntoPages = (data, itemsPerPage) => {
        const pages = [];
        for (let i = 0; i < data.length; i += itemsPerPage) {
            pages.push(data.slice(i, i + itemsPerPage));
        }
        return pages;
    }

    const itemsPerPage  = 70;
    const checkedData   = data.filter(item => checkedItems[item.id]);
    const pages         = splitDataIntoPages(checkedData, itemsPerPage);

    const PCT   = 100.0;
    const TOP   = 30.5;
    const LEFT  = 23;
    const SZ    = 20.0;
    const GAP   = 4.0;
    const [ratioX, setRatioX] = useState(PCT);
    const [ratioY, setRatioY] = useState(PCT);
    useEffect(() => {
        document.querySelectorAll('.qr-grid').forEach(grid => {
            grid.style.columnGap            = `${GAP * ratioX / PCT}mm`;
            grid.style.rowGap               = `${GAP * ratioY / PCT}mm`;
            grid.style.gridTemplateColumns  = `repeat(7,  ${SZ * ratioX / PCT}mm)`;
            grid.style.gridTemplateRows     = `repeat(10, ${SZ * ratioY / PCT}mm)`;
        });
        document.querySelectorAll('.qr-code-container').forEach(container => {
            container.style.width   = `${SZ * ratioX / PCT}mm`;
            container.style.height  = `${SZ * ratioY / PCT}mm`;
        });
    }, [ratioX, ratioY]);

    const [offsetTop, setOffsetTop]     = useState(TOP);
    const [offsetLeft, setOffsetLeft]   = useState(LEFT);
    useEffect(() => {
        document.querySelectorAll('.page').forEach(page => {
            page.style.paddingTop  = `${offsetTop}mm`;
            page.style.paddingLeft = `${offsetLeft}mm`;
        });
    }, [offsetTop, offsetLeft]);

    return (
        <div>
            <div id="qr-settings">
                <div>
                    <label>拡大比率(横):</label>
                    <input type="text" value={ratioX} onChange={(e) => setRatioX(e.target.value)} />%
                </div>
                <div>
                    <label>拡大比率(縦):</label>
                    <input type="text" value={ratioY} onChange={(e) => setRatioY(e.target.value)} />%
                </div>
                <div>
                    <label>余白(上):</label>
                    <input type="text" value={offsetTop} onChange={(e) => setOffsetTop(e.target.value)} />mm
                </div>
                <div>
                    <label>余白(左):</label>
                    <input type="text" value={offsetLeft} onChange={(e) => setOffsetLeft(e.target.value)} />mm
                </div>
                <div>
                    <button onClick={() => window.print()}>印刷プレビュー</button>
                </div>
            </div>
            {pages.map((pageData, pageIndex) => (
                <React.Fragment key={pageIndex}>
                    <div className="space">&nbsp;</div>
                    <div className="page">
                        <div className="qr-grid">
                            {pageData.map(item => (
                                <div key={item.id} className="qr-code-container">
                                    <div className="qr-code-wrapper">
                                        <QRCode size={256} level="Q" value={get_qr_code_token(item)} />
                                    </div>
                                    <p>{truncateText(item.id + ":" + item.name, 10)}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="space">&nbsp;</div>
                </React.Fragment>
            ))}
        </div>
    );
}
