import {useAuth} from "../AuthProvider";
import { useEffect } from "react";

export default function LogList({data}) {
    const { setLogList } = useAuth();
    useEffect(() => {
        if (data) {
            setLogList(data);
        }
    }, [data, setLogList]);

    if (!data) {
        return <div>データがありません</div>;
    }
    return (
        <div>
            {data.map(item => (
                <LogRow key={item.id} item={item} />
            ))}
        </div>
    );
}

function LogRow({item}) {
    const uri = "/log_detail/" + item.id + "/";
    return (
        <div>
            <a href={uri}>
                <p>{item.id}: {item.name}</p>
                <p>{item.in_action.storage.name}</p>
                <p>入庫: {item.in_action.created_at}</p>
                {item.out_action ?
                    <p>出庫: {item.out_action.created_at}</p>
                :
                    ""
                }
            </a>
            <hr />
        </div>
    );
}
