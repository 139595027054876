import { get_role_if_admin } from "../util";
import {useAuth} from "../AuthProvider";
import { useEffect } from "react";

export default function UserList({data}) {
    const { setUserList } = useAuth();
    useEffect(() => {
        if (data) {
            setUserList(data);
        }
    }, [data, setUserList]);

    if (!data) {
        return <div>データがありません</div>;
    }
    return (
        <div>
            {data.map(user => (
                <UserRow key={user.id} user={user} />
            ))}
        </div>
    );
}

function UserRow({user}) {
    const role = get_role_if_admin(user.role)
    return (
        <div>
            <a href={user.uri}>
                <p>
                    {user.id}: {user.name} {role}
                </p>
                <p>{user.email}</p>
            </a>
            <hr />
        </div>
    );
}
