import Fetch from "../Fetch";
import StorageList from "./StorageList";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchStorageList() {
    return (
        <Fetch
            url={new URL(ACTION.LIST, TYPE.STORAGE)}
            renderSuccess={(data) => <StorageList data={data.data} />}
        />
    );
}
