import {useAuth} from "../AuthProvider";
import { useEffect } from "react";

export default function StorageList({data}) {
    const { setStorageList } = useAuth();
    useEffect(() => {
        if (data) {
            setStorageList(data);
        }
    }, [data, setStorageList]);

    if (!data) {
        return <div>データがありません</div>;
    }
    return (
        <div>
            {data.map(storage => (
                <StorageRow key={storage.id} storage={storage} />
            ))}
        </div>
    );
}

function StorageRow({storage}) {
    return (
        <div>
            <a href={storage.uri}>
                <p>{storage.id}: {storage.name}</p>
                <p>{storage.address}</p>
            </a>
            <hr />
        </div>
    );
}
