import {useAuth} from "../AuthProvider";
import { useEffect } from "react";

export default function ItemList({data}) {
    const { setItemList } = useAuth();
    useEffect(() => {
        if (data) {
            setItemList(data);
        }
    }, [data, setItemList]);

    if (!data) {
        return <div>データがありません</div>;
    }
    return (
        <div>
            {data.map(item => (
                <ItemRow key={item.id} item={item} />
            ))}
            {/* 
            <hr />

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data.map(item => (
                    <div key={item.id} style={{ margin: "10px", padding: "10px", textAlign: 'center' }}>
                        <QRCode size={128} value={get_qr_code_token(item)} />
                        <p>{item.id}: {item.name}</p>
                    </div>
                ))}
            </div>
            */}
        </div>
    );
}

function ItemRow({item}) {
    return (
        <div>
            <a href={item.uri}>
                <p>{item.id}: {item.name}</p>
                {item.expire ?
                    <p>賞味期限: {item.expire}</p>
                :
                    ""
                }
                {item.storage ?
                    <p>{item.storage.name}</p>
                :
                    ""
                }
            </a>
            <hr />
        </div>
    );
}
