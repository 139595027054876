import Fetch from "../Fetch";
import ItemList from "./ItemList";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchItemList() {
    return (
        <Fetch
            url={new URL(ACTION.LIST, TYPE.ITEM)}
            renderSuccess={(data) => <ItemList data={data.data} />}
        />
    );
}
