import Fetch from "../Fetch";
import UserList from "./UserList";
import { URL, TYPE, ACTION } from "../URL";

export default function FetchUserList() {
    return (
        <Fetch
            url={new URL(ACTION.LIST, TYPE.USER)}
            renderSuccess={(data) => <UserList data={data.data} />}
        />
    );
}
