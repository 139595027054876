import React, { useEffect } from "react";
import { useEditForm } from "../useEditForm";
import { Input } from "../Input";
import { make_role_options } from "../util";
import { TYPE, ACTION } from "../URL";

export default function StorageUpdate({ data }) {
  // _d(data, "data");
  const fields = [
    { name: "name",     label: "名前" },
    { name: "address",  label: "住所" },
  ];

  const names       = fields.map(field => field.name);
  const storage_id  = data.id;
  const { register, handleSubmit, errors, formData, setFormData } = useEditForm(data, names, TYPE.STORAGE, ACTION.UPDATE, storage_id);

  useEffect(() => {
    setFormData(data);
  }, [data, setFormData]);

  return (
    <form onSubmit={handleSubmit}>
      <table>
        <tbody>

          <tr>
            <th>ID</th>
            <td>{data.id}</td>
          </tr>

          {fields.map((field) => (
            <tr key={field.name}>
              <Input
                name        = {field.name}
                label       = {field.label}
                options     = {field.options}
                formData    = {formData}
                setFormData = {setFormData}
                register    = {register}
                errors      = {errors}
              />
            </tr>
          ))}

        </tbody>
      </table>
      <button type="submit">SUBMIT</button>
    </form>
  );
}
