import { useState, useEffect, useRef } from "react";
import { useAuth, get_user_id } from "./AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { URL, ACTION, TYPE } from "./URL";
import { _l, _d } from "./util";
import CSV from "./csv";

function makePwchangeURL(url) {
    if (url && url.action !== ACTION.DETAIL)  return null;
    if (url.type !== TYPE.USER)               return null; // only user detail can change password
    if (url.id != get_user_id())              return null; // only user can change password
    return new URL(ACTION.PWCHANGE, url.type);
}

function makeInsertURL(url) {
    if (url && url.action !== ACTION.LIST)  return null;
    if (url.type === TYPE.LOG)              return null; // no insert for log.
    return new URL(ACTION.INSERT, url.type, url.id);
}

function makeUpdateURL(url) {
    if (url && url.action !== ACTION.DETAIL) return null;
    if (url.type === TYPE.LOG)              return null; // no update for log.
    return new URL(ACTION.UPDATE, url.type, url.id);
}

function makeDeleteURL(url) {
    if (url && url.action !== ACTION.DETAIL) return null;
    if (url.type === TYPE.LOG)              return null; // no delete for log.
    return new URL(ACTION.DELETE, url.type, url.id);
}

function isStorageDetail(url) {
    return url && url.type === TYPE.STORAGE && url.action === ACTION.DETAIL;
}

function makeScaniURL(url) {
    if (!isStorageDetail(url))  return;
    return new URL(ACTION.SCAN, null, url.id, {"bound": "inbound"});
}

function makeScanoURL(url) {
    if (!isStorageDetail(url))  return;
    return new URL(ACTION.SCAN, null, url.id, {"bound": "outbound"});
}
  
function appendLink(urlString, text) {
    if (!urlString) return "";
    return <><a href={urlString}>{text}</a>|</>
};

function appendURLLink(url, text) {
    if (!url) return "";
    return appendLink(url.getPath(), text);
};

function appendActionLink(url, action) {
    return appendURLLink(url, URL.STRINGS[action]);
};

function appendNavLink(action, type = null) {
    const url = new URL(action, type);
    return appendURLLink(url, url.getTitle());
}

function appendDownloadLink(url, anchorRef, userList, storageList, itemList, logList) {
    if (!url)                       return "";
    if (url.action !== ACTION.LIST) return "";
    const csvStr    = CSV.makeCSVString(url, userList, storageList, itemList, logList);
    const filename  = CSV.makeDownloadFileName(url);
    const onClick   = () => CSV.downloadCSV(anchorRef, csvStr, filename);
    return (
        <>
            <a href="#download" onClick={onClick}>
                CSVダウンロード
            </a>
            |
            <a ref={anchorRef} className='hidden'></a>
        </>
    );
}

function appendUploadLink(url, uploadRef, navigate) {
    if (!url)                       return "";
    if (url.action !== ACTION.LIST) return "";
    if (url.type   !== TYPE.ITEM)   return "";

    const onClick  = () => uploadRef.current.click();
    const onChange = (event) => CSV.uploadCSV(event, navigate);
    return (
        <>
            <a href="#" onClick={onClick}>
                CSVアップロード
            </a>
            <input
                type="file"
                accept=".csv"
                ref={uploadRef}
                style={{ display: 'none' }}
                onChange={onChange}
            />
        </>
    );
}

export default function Header() {
    const location      = useLocation();
    const navigate      = useNavigate();
    const anchorRef     = useRef(null)
    const uploadRef     = useRef(null)
    const [url, setURL] = useState(URL.parse(location.pathname));
    const { isAuthenticated, isAdmin, userList, storageList, itemList, logList } = useAuth();

    // location の変更を useEffect で監視する.
    useEffect(() => {
        setURL(URL.parse(location.pathname));
    }, [location]);

    if (!isAuthenticated) return "";

    // _l("navigations:", navigations);
    // _d(navigations);
    // MEMO: type = Symbol(react.element)

    const makeTitle = (isAdmin, url) =>{
        if (!url)       return "";
        const title = url.getTitle();
        if (isAdmin)    return "[管理者ログイン中] " + title;
        else            return title;
    }

    return (
        <header>
            <div>
                NAV:
                <a href="#" onClick={() => navigate(-1)}>
                    戻る
                </a>
                |
                {           appendNavLink(ACTION.TOP)}
                {isAdmin && appendNavLink(ACTION.LIST, TYPE.USER)}
                {           appendNavLink(ACTION.LIST, TYPE.STORAGE)}
                {           appendNavLink(ACTION.LIST, TYPE.ITEM)}
                {isAdmin && appendNavLink(ACTION.LIST, TYPE.LOG)}
                {isAdmin && appendNavLink(ACTION.QR)}
                {           appendNavLink(ACTION.LOGOUT)}
            </div>
            <div>
                ACTIONS:
                {           appendActionLink(makePwchangeURL(url), ACTION.PWCHANGE)}
                {isAdmin && appendActionLink(makeInsertURL(url), ACTION.INSERT)}
                {isAdmin && appendActionLink(makeUpdateURL(url), ACTION.UPDATE)}
                {isAdmin && appendActionLink(makeDeleteURL(url), ACTION.DELETE)}
                {isAdmin && appendActionLink(makeScaniURL(url),  ACTION.INBOUND)}
                {           appendActionLink(makeScanoURL(url),  ACTION.OUTBOUND)}
                {           appendDownloadLink(url, anchorRef, userList, storageList, itemList, logList)}
                {isAdmin && appendUploadLink(url, uploadRef, navigate)}
            </div>
            <hr />
            <h1>{makeTitle(isAdmin, url)}</h1>
            <hr />
        </header>
    );
}
