import ItemList from '../Items/ItemList';

export default function StorageDetail({data}) {
    return (
        <>
        <table>
            <tbody>
                <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                </tr>
                <tr>
                    <th>名前</th>
                    <td>{data.name}</td>
                </tr>
                <tr>
                    <th>住所</th>
                    <td>{data.address}</td>
                </tr>
            </tbody>
        </table>
        <h2>■備蓄品一覧</h2>
        <ItemList data={data.items} />
        </>
    );
}
