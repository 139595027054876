import React, { useState, useEffect } from "react";
import { useEditForm } from "../useEditForm";
import { Input } from "../Input";
import { TYPE, ACTION } from "../URL";

export default function StorageInsert() {
  const fields = [
    { name: "name",     label: "名前" },
    { name: "address",  label: "住所" },
  ];

  const names   = fields.map(field => field.name);

  const storage_id = 0;
  const [data]  = useState({});
  const { register, handleSubmit, errors, formData, setFormData } = useEditForm(data, names, TYPE.STORAGE, ACTION.INSERT, storage_id);
  
  useEffect(() => {
    setFormData(data);
  }, [data, setFormData]);
  
  return (
    <form onSubmit={handleSubmit}>
      <table>
        <tbody>
        {fields.map((field) => (
          <tr key={field.name}>
            <Input
              name={field.name}
              label={field.label}
              options={field.options}
              formData={formData}
              setFormData={setFormData}
              register={register}
              errors={errors}
            />
          </tr>
        ))}
        </tbody>
      </table>
      <button type="submit">作成</button>
    </form>
  );
}
