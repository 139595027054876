import StorageDetail from "./StorageDetail";
import { useEditForm } from "../useEditForm";
import { URL, TYPE, ACTION } from "../URL";

export default function StorageDelete({data}) {
    const user_id   = data.id;
    const names     = [];
    const action    = ACTION.DELETE;
    const type      = TYPE.STORAGE;
    const name      = URL.STRINGS[type];
    const { handleSubmit } = useEditForm(data, names, type, action, user_id);

    return (<>
        <h1>{name}削除</h1>
        <p>この{name}を削除してよろしいですか？</p>
        <p>※削除した{name}はもとにもどせません</p>
        <form onSubmit={handleSubmit}>
            <button type="submit">削除する</button>
        </form>

        <hr/>
        <StorageDetail data={data} />
    </>)
}
