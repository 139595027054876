import React, { useEffect } from "react";
import { useEditForm } from "../useEditForm";
import { Input } from "../Input";
import { make_role_options } from "../util";
import { TYPE, ACTION } from "../URL";

export default function UserUpdate({ data }) {
  // _d(data, "data");
  const fields = [
    { name: "name",     label: "名前" },
    { name: "email",    label: "メールアドレス" },
    { name: "role",     label: "権限", options: make_role_options() }
  ];

  const names   = fields.map(field => field.name);
  const user_id = data.id;
  const { register, handleSubmit, errors, formData, setFormData } = useEditForm(data, names, TYPE.USER, ACTION.UPDATE, user_id);
  const owned_storage_ids = new Set(data.storages.map(storage => storage.id));
  // _d(owned_storage_ids, "owned_storage_ids");

  useEffect(() => {
    setFormData(data);
  }, [data, setFormData]);

  return (
    <form onSubmit={handleSubmit}>
      <table>
        <tbody>

          <tr>
            <th>ID</th>
            <td>{data.id}</td>
          </tr>

          {fields.map((field) => (
            <tr key={field.name}>
              <Input
                name        = {field.name}
                label       = {field.label}
                options     = {field.options}
                formData    = {formData}
                setFormData = {setFormData}
                register    = {register}
                errors      = {errors}
              />
            </tr>
          ))}

          <tr>
            <td colSpan="2">
              <h3>■管理している倉庫一覧</h3>
            </td>
          </tr>

          {data.all_storages.map((storage) => (
            <tr key={storage.id}>
              <td>
                <input
                  type            = "checkbox"
                  name            = "update_storages[]"
                  value           = {storage.id}
                  defaultChecked  = {owned_storage_ids.has(storage.id)}
                  {...register("update_storages[]")}
                />
              </td>
              <td>{storage.id}:{storage.name}</td>
            </tr>
          ))}

        </tbody>
      </table>
      <button type="submit">SUBMIT</button>
    </form>
  );
}
