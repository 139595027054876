import StorageList from "../Storages/StorageList";
import { get_role } from "../util";

export function UserDetailBase({data}) {
    return (
        <>
            <tr>
                <th>ID</th>
                <td>{data.id}</td>
            </tr>
            <tr>
                <th>名前</th>
                <td>{data.name}</td>
            </tr>
            <tr>
                <th>メールアドレス</th>
                <td>{data.email}</td>
            </tr>
            <tr>
                <th>権限</th>
                <td>{get_role(data.role)}</td>
            </tr>
        </>                
    );
}

export default function UserDetail({data}) {
    return (
        <>
            <table>
                <tbody>
                    <UserDetailBase data={data} />
                </tbody>
            </table>
            <h3>■管理している倉庫一覧</h3>
            <StorageList data={data.storages} />
        </>
    );
}
