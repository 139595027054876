import { useState } from "react";
import { useZxing } from "react-zxing";
import { _d, _l, parse_qr_code } from "./util";
import { get_user_id } from "./AuthProvider";
import { URL } from "./URL";
import {Howl} from 'howler';

export const BarcodeScanner = ({url, storage_id}) => {
  _l("storage_id = " + storage_id);
  const bound     = URL.STRINGS[url.action];
  const [item_ids, setItemIds]  = useState([]);
  const [messages, setMessages] = useState([]);


  const sound_read = new Howl({
    src: ['/static/read.mp3'],
    preload: true,
  });
  const sound_register = new Howl({
    src: ['/static/register.mp3'],
    preload: true,
  });
  const sound_fail = new Howl({
    src: ['/static/fail.mp3'],
    preload: true,
  });

  /*
  sound.on('end', function(){
    _l('sound end!');
  });
  const onClickPlay = () => {
    sound.play();
  };
  */

  const putMessage = (message) => {
    setMessages((messages) => [message, ...messages]);
  };

  const requestAPI = async (request_data) => {
    putMessage("requesting... item_id = " + request_data.item_id);
    const response  = await url.fetch(request_data);
    if (response.ok) {
      const data    = await response.json();
      _d(data, "response data: ");
      if (data.status === "OK") {
        putMessage(bound + " 成功: ID = " + request_data.item_id);
        sound_register.play();
        return;
      }
      if (data.status === "FAILED") {
        const details = data.details;
        putMessage(bound + " 失敗: ID = " + request_data.item_id + ", " + details.msg);
        sound_fail.play();
        return;
      }
    }
  };

  const { ref } = useZxing({
    onDecodeResult(result) {
      _l("onDecodeResult: " + result.text);
      const item_id = parse_qr_code(result.text);
      if (!item_id)                   return;
      if (item_ids.includes(item_id)) return;
      putMessage("item_id = " + item_id);
      const data = {
        user_id:    get_user_id(),
        storage_id: storage_id,
        item_id:    item_id,
      };
      sound_read.play();
      setItemIds(prevItemIds => [...prevItemIds, item_id]);
      requestAPI(data);
    },
  });

  return (
    <>
      <video width="320" ref={ref} />
      <div>
        <div>Log:</div>
        {messages.map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </div>
    </>
  );
};
