import QRCode from "react-qr-code";
import { get_qr_code_token } from "../util";

export default function ItemDetail({data}) {
    const sizes = [32, 64, 128];
    const levels = ['L', 'M', 'Q', 'H'];
        return (
        <>
        <table>
            <tbody>
                <tr>
                    <th>ID</th>
                    <td>{data.id}</td>
                </tr>
                <tr>
                    <th>名前</th>
                    <td>{data.name}</td>
                </tr>
                <tr>
                    <th>消費期限</th>
                    <td>{data.expire}</td>
                </tr>
                {data.storage ? (
                <tr>
                    <th>倉庫</th>
                    <td>
                        <a href={data.storage.uri}>
                            {data.storage.id}<br/>
                            {data.storage.name}<br/>
                            {data.storage.address}<br/>
                        </a>
                    </td>
                </tr>
                ) : (
                <tr>
                    <th>倉庫</th>
                    <td>なし</td>
                </tr>
                )}
            </tbody>
        </table>
        <h3>QRCode</h3>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
                {sizes.map(size => 
                    levels.map(level => (
                        <QRCode key={`${size}-${level}`} size={size} level={level} value={get_qr_code_token(data)} />
                    ))
                )}
        </div>
        </>
    );
}
